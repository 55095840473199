@font-face {
    font-family: 'Digital Numbers';
    src: url('/assets/font/DigitalNumbers-Regular.woff') format('woff'),
        url('/assets/font/DigitalNumbers-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import "~leaflet/dist/leaflet.css";

ion-title {
    font-weight: 400;
    letter-spacing: 0.4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

.split-pane-visible>.split-pane-side {
    min-width: 280px !important;
    max-width: 280px !important;
}

.histo-confirm-alert .alert-wrapper .radio-del .alert-radio-label {
    color: var(--ion-color-danger);
}

ion-thumbnail {
    --size: var(--thumbsize);
}

ion-toolbar {
    --padding-top: 0;
    --padding-bottom: 0;
    --min-height: 46px;
    max-height: 46px;
}

ion-item {
    --highlight-height: 2px;
    --show-full-highlight: 1;

    &.ion-invalid {
        .att-label {
            color: var(--ion-color-danger);
        }
    }
}

ion-header.secondary-header {
    background-color: var(--ion-toolbar-background);

    ion-toolbar {
        margin: 0 auto;
        max-width: 1124px;

        ion-title {
            color: var(--ion-text-color);
            height: 46px;
            padding: 0;
        }

        ion-back-button {
            height: 46px;
            --padding-top: 0;
            --padding-bottom: 0;
            --padding-start: 0;
            --padding-end: 0;
        }

        ion-button,
        btn-nav-right {
            height: 46px;
        }

        ion-icon {
            --icon-font-size: 24px;
            align-self: center;
        }
    }

    &::after {
        background-image: none;
    }

    &.md {
        border-bottom: 1px solid var(--ion-border-color);
    }

}

ion-card.app-card {
    text-align: left;
    box-shadow: none;
    margin: 8px;

    #title {
        padding: 8px;
        background: var(--panel-title-background);
        color: var(--panel-title-color);
        height: 32px;
        font-size: 14px;
    }

    #content {
        min-height: 54px;
        background: var(--panel-content-background);
    }
}

.dashseg-cont {
    width: 100%;
    padding: 4px 16px;

    ion-segment.dash {
        margin: 8px auto;

        &.ios {
            --background: var(--ion-tab-bar-background);
        }

        ion-segment-button {
            --color: var(--ion-tab-bar-color);
            --color-checked: var(--ion-tab-bar-color-selected);

            &.ios {
                --indicator-color: var(--ion-background-color);
            }

            ion-label {
                font-size: 14px;
            }
        }
    }
}

.centered-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon.no-content {
        color: var(--ion-color-medium);
        font-size: 64px;
    }
}

.view-content {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: left;

    max-width: 1116px;

    &.narrow {
        max-width: 500px;
    }

    // only used in /pub space
    ion-item.input-item {
        --padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;

        ion-icon {
            align-self: center;
            color: var(--ion-icon-color);
        }

        ion-label {
            color: var(--ion-std-color-labels);
            font-weight: 300;
        }
    }

    ion-item.nohighlight {
        --highlight-height: 0;
    }

    ion-button {
        text-transform: none;
        margin-top: var(--std-margin);
        width: 100%;

        @media (min-width:280px) {
            width: 280px;
        }

        &.btn-submit {
            margin-top: calc(var(--std-margin) * 2);
            justify-self: center;
        }

        &.btn-remove-itm {
            margin: 0 12px 0 0;
            width: auto;
            height: auto;
            --padding-start: 0;
            --padding-end: 0;
        }
    }

    .att-wrapper {
        width: 100%;
        margin: 2px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        .att-label {
            font-weight: 300;
            line-height: 39px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 16px 0 0;
        }

        .att-value-cont {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            flex-shrink: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .att-value {
                height: 39px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                text-align: end;
                align-items: center;

                ion-input.att-val-input {
                    --background: var(--ion-color-step-100);
                    width: 100px;
                }

                ion-label.att-val-show {
                    line-height: 39px;
                    max-width: unset;

                    &.val-padded {
                        padding-right: 16px;
                    }
                }
            }

            .att-value-area {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                text-align: end;
                align-items: center;

                ion-textarea {
                    text-align: left;
                    margin: 0;
                    --padding-end: 16px;

                    &.att-val-input {
                        --background: var(--ion-color-step-100);
                    }
                }
            }
        }
    }
}

.settings-container {
    padding: 0 var(--app-fair-margin) var(--app-fair-margin) var(--app-fair-margin);

    .settings-head {
        display: flex;
        justify-content: space-between;
        margin: 24px 0 8px 0;

        div.settings-label {
            display: flex;
            align-items: flex-end;
        }

        div.settings-btn {
            ion-button {
                max-width: 120px;
                margin: 0;
            }
        }
    }

    div.settings-head {
        ion-note {
            font-size: 16px;
        }
    }

    .settings-card {
        text-align: left;
        box-shadow: none;
        --background: var(--ion-color-step-100);
        margin: 0;
        padding: 0;
        font-size: 16px;

        ion-list {
            margin: 0;

            ion-item {
                --background: var(--ion-color-step-100);
                --padding-start: 16px;
                --padding-end: 8px;
                --inner-padding-start: 0;
                --inner-padding-end: 0;

                &.warn-itm {
                    --background: rgba(var(--ion-color-panel-dflt-rgb), 0.2);
                }

                ion-label {
                    font-size: 16px;

                    &.item-lbl {
                        font-weight: 300;
                    }
                }
            }
        }

        ion-card-subtitle {
            font-size: 18px;
        }
    }
}

.date-selector-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);

    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.pic-viewer-modal {
    --width: 100%;
    --height: 100%;

    ion-modal::part(content) {
        --border-radius: 0;

        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

div.dash-view-cont {
    padding: 8px;

    ion-grid {
        --ion-grid-padding: 0;

        ion-col {
            --ion-grid-column-padding: 0;
        }
    }

    .content {
        width: 100%;
        height: 100%;

        msg-item-view {
            --item-background: var(--ion-color-light);
        }

        elem-list-item-view {
            --item-padding-start: 4px;
            --item-background: var(--ion-color-light);
            --item-delbtn-margin-left: 8px;
            --item-delbtn-margin-right: 4px;

            div.label {
                margin-right: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                ion-note {
                    margin-top: 4px;
                }
            }

            elem-thumb {
                margin-right: 8px;
            }
        }

        ion-spinner {
            margin: 16px auto;
        }
    }
}

.list-view-container {
    height: 100%;
    width: 100%;
    padding-top: 8px;

    elem-thumb {
        margin-right: 20px;
    }
}

.std-popover {
    --width: auto;
    --max-width: 300px;
}

.dashsum-container {
    width: 100%;
    min-height: 160px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 28px 20px 16px 20px;

    .sum-pic-container {
        min-height: 128px;
        margin-right: 20px;
    }

    .sum-text-container {
        margin: 4px 0;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .sum-text {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        ion-button {
            margin: 10px 0 0 0;
            width: 100px;
        }
    }
}

ion-note.no-entries {
    margin: 0 auto;
    font-weight: 300;
    font-style: italic;
    font-size: 17px;
    align-self: center;
    color: var(--ion-tab-bar-color);
}