/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

:root {
  --ion-color-primary: #2f4d8f;
  --ion-color-primary-rgb: 47, 77, 143;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #29447e;
  --ion-color-primary-tint: #445f9a;

  --ion-color-secondary: #2a973e;
  --ion-color-secondary-rgb: 42, 151, 62;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #258537;
  --ion-color-secondary-tint: #3fa151;

  --ion-color-tertiary: #685bb3;
  --ion-color-tertiary-rgb: 104, 91, 179;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5c509e;
  --ion-color-tertiary-tint: #776bbb;

  --ion-color-success: #068830;
  --ion-color-success-rgb: 6, 136, 48;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #05782a;
  --ion-color-success-tint: #1f9445;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-google: #cc181e;
  --ion-color-google-rgb: 204, 24, 30;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #b4151a;
  --ion-color-google-tint: #d12f35;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  --ion-color-panel-contacts: #5a88ce;
  --ion-color-panel-contacts-rgb: 90, 136, 206;
  --ion-color-panel-contacts-contrast: #ffffff;
  --ion-color-panel-contacts-contrast-rgb: 255, 255, 255;
  --ion-color-panel-contacts-shade: #4f78b5;
  --ion-color-panel-contacts-tint: #6b94d3;

  --ion-color-panel-props: #69bb7b;
  --ion-color-panel-props-rgb: 105, 187, 123;
  --ion-color-panel-props-contrast: #ffffff;
  --ion-color-panel-props-contrast-rgb: 255, 255, 255;
  --ion-color-panel-props-shade: #5ca56c;
  --ion-color-panel-props-tint: #78c288;

  --ion-color-panel-rems: #a393ce;
  --ion-color-panel-rems-rgb: 163, 147, 206;
  --ion-color-panel-rems-contrast: #ffffff;
  --ion-color-panel-rems-contrast-rgb: 255, 255, 255;
  --ion-color-panel-rems-shade: #8f81b5;
  --ion-color-panel-rems-tint: #ac9ed3;

  --ion-color-panel-lea: #e2ca5e;
  --ion-color-panel-lea-rgb: 226, 202, 94;
  --ion-color-panel-lea-contrast: #ffffff;
  --ion-color-panel-lea-contrast-rgb: 255, 255, 255;
  --ion-color-panel-lea-shade: #c7b253;
  --ion-color-panel-lea-tint: #e5cf6e;

  --ion-color-panel-dflt: #b35773;
  --ion-color-panel-dflt-rgb: 179, 87, 115;
  --ion-color-panel-dflt-contrast: #ffffff;
  --ion-color-panel-dflt-contrast-rgb: 255, 255, 255;
  --ion-color-panel-dflt-shade: #9e4d65;
  --ion-color-panel-dflt-tint: #bb6881;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #202020;
  --ion-text-color-rgb: 32, 32, 32;
  --ion-card-color: #202020;
  --ion-card-color-rgb: 32, 32, 32;
  --ion-panel-icon-color: var(--ion-color-step-150);

  --ion-color-step-50: #f4f4f4;
  --ion-color-step-100: #e9e9e9;
  --ion-color-step-150: #dedede;
  --ion-color-step-200: #d2d2d2;
  --ion-color-step-250: #c7c7c7;
  --ion-color-step-300: #bcbcbc;
  --ion-color-step-350: #b1b1b1;
  --ion-color-step-400: #a6a6a6;
  --ion-color-step-450: #9b9b9b;
  --ion-color-step-500: #909090;
  --ion-color-step-550: #848484;
  --ion-color-step-600: #797979;
  --ion-color-step-650: #6e6e6e;
  --ion-color-step-700: #636363;
  --ion-color-step-750: #585858;
  --ion-color-step-800: #4d4d4d;
  --ion-color-step-850: #414141;
  --ion-color-step-900: #363636;
  --ion-color-step-950: #2b2b2b;

  /*
  
  --ion-toolbar-segment-color	Color of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-color-checked	Color of the checked Segment Buttons in the Toolbar
  --ion-toolbar-segment-background	Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-background-checked	Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-indicator-color	Color of the Segment Button indicator in the Toolbar
  --ion-item-background	Background of the Item
  --ion-item-border-color	Border color of the Item
  --ion-item-color	Color of the components in the Item
*/
  --ion-overlay-background-color: #ffffff;
  --main-toolbar-background: var(--ion-color-primary);
  --main-toolbar-color: var(--ion-color-primary-contrast);
  --ion-toolbar-color: var(--ion-text-color);
  --list-headings-background: rgba(var(--ion-color-primary-rgb), 0.7);
  --ion-tab-bar-color-selected: var(--ion-color-primary);


  --ion-font-family: 'Roboto', sans-serif;
  --ion-headings-font-weight: 400;
  --ion-padding: var(--app-fair-margin);


  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --std-margin: var(--app-broad-margin);

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;

  --att-min-width: 180px;

  --thumbsize: 56px;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */
/*
.ios {

}
*/
/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

@media (prefers-color-scheme: dark) {
  :root {
    --ion-background-color: #202020;
    --ion-background-color-rgb: 32, 32, 32;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-card-color: #ffffff;
    --ion-card-color-rgb: 255, 255, 255;
    --ion-panel-icon-color: var(--ion-icon-color);

    --ion-color-step-50: #2b2b2b;
    --ion-color-step-100: #363636;
    --ion-color-step-150: #414141;
    --ion-color-step-200: #4d4d4d;
    --ion-color-step-250: #585858;
    --ion-color-step-300: #636363;
    --ion-color-step-350: #6e6e6e;
    --ion-color-step-400: #797979;
    --ion-color-step-450: #848484;
    --ion-color-step-500: #909090;
    --ion-color-step-550: #9b9b9b;
    --ion-color-step-600: #a6a6a6;
    --ion-color-step-650: #b1b1b1;
    --ion-color-step-700: #bcbcbc;
    --ion-color-step-750: #c7c7c7;
    --ion-color-step-800: #d2d2d2;
    --ion-color-step-850: #dedede;
    --ion-color-step-900: #e9e9e9;
    --ion-color-step-950: #f4f4f4;

    --ion-color-primary: #e9e9e9;
    --ion-color-primary-rgb: 233, 233, 233;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #cdcdcd;
    --ion-color-primary-tint: #ebebeb;

    --ion-color-panel-contacts: #314e79;
    --ion-color-panel-contacts-rgb: 49, 78, 121;
    --ion-color-panel-contacts-contrast: #ffffff;
    --ion-color-panel-contacts-contrast-rgb: 255, 255, 255;
    --ion-color-panel-contacts-shade: #2b456a;
    --ion-color-panel-contacts-tint: #466086;

    --ion-color-panel-props: #467d52;
    --ion-color-panel-props-rgb: 70, 125, 82;
    --ion-color-panel-props-contrast: #ffffff;
    --ion-color-panel-props-contrast-rgb: 255, 255, 255;
    --ion-color-panel-props-shade: #3e6e48;
    --ion-color-panel-props-tint: #598a63;

    --ion-color-panel-rems: #4f4763;
    --ion-color-panel-rems-rgb: 79, 71, 99;
    --ion-color-panel-rems-contrast: #ffffff;
    --ion-color-panel-rems-contrast-rgb: 255, 255, 255;
    --ion-color-panel-rems-shade: #463e57;
    --ion-color-panel-rems-tint: #615973;

    --ion-color-panel-lea: #91823c;
    --ion-color-panel-lea-rgb: 145, 130, 60;
    --ion-color-panel-lea-contrast: #ffffff;
    --ion-color-panel-lea-contrast-rgb: 255, 255, 255;
    --ion-color-panel-lea-shade: #807235;
    --ion-color-panel-lea-tint: #9c8f50;

    --ion-color-panel-dflt: #693343;
    --ion-color-panel-dflt-rgb: 105, 51, 67;
    --ion-color-panel-dflt-contrast: #ffffff;
    --ion-color-panel-dflt-contrast-rgb: 255, 255, 255;
    --ion-color-panel-dflt-shade: #5c2d3b;
    --ion-color-panel-dflt-tint: #784756;

    --main-toolbar-background: var(--ion-color-step-50);
    --main-toolbar-color: var(--ion-text-color);
    --ion-toolbar-color: var(--ion-text-color);
    --list-headings-background: rgba(var(--ion-color-primary-rgb), 0.4);
    --ion-tab-bar-color-selected: var(--ion-color-step-900);
  }

  .md {
    /* Use the dark color as the background for the toolbar */
    /*    --ion-toolbar-background: var(--ion-color-dark);*/

    /* Change the color of the toolbar components */
    /*    --ion-toolbar-color: #fff;*/

    /* Change the color of the activated toolbar components */
    /*    --ion-toolbar-color-activated: #fff;*/

    /* Change the color of the unchecked segment buttons */
    /*    --ion-toolbar-color-unchecked: rgba(255, 255, 255, .6);*/

    /* Change the color of the checked segment button */
    /*    --ion-toolbar-color-checked: #fff;*/
  }

}

:root {
  --ion-overlay-background-color: var(--ion-background-color);
  --ion-tab-bar-background: var(--ion-color-step-100);
  --ion-tab-bar-background-focused: var(--ion-color-step-100);
  --ion-tab-bar-color: var(--ion-color-step-550);
  --ion-toolbar-background: var(--ion-color-step-100);
  --ion-border-color: var(--ion-color-step-300);
  --ion-icon-color: var(--ion-color-step-550);

  --panel-title-background: var(--ion-color-panel-dflt);
  --panel-title-color: var(--ion-color-panel-dflt-contrast);
  --panel-content-background: rgba(var(--ion-color-panel-dflt-rgb), 0.1);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-panel-contacts {
  --ion-color-base: var(--ion-color-panel-contacts);
  --ion-color-base-rgb: var(--ion-color-panel-contacts-rgb);
  --ion-color-contrast: var(--ion-color-panel-contacts-contrast);
  --ion-color-contrast-rgb: var(--ion-color-panel-contacts-contrast-rgb);
  --ion-color-shade: var(--ion-color-panel-contacts-shade);
  --ion-color-tint: var(--ion-color-panel-contacts-tint);
}

.ion-color-panel-props {
  --ion-color-base: var(--ion-color-panel-props);
  --ion-color-base-rgb: var(--ion-color-panel-props-rgb);
  --ion-color-contrast: var(--ion-color-panel-props-contrast);
  --ion-color-contrast-rgb: var(--ion-color-panel-props-contrast-rgb);
  --ion-color-shade: var(--ion-color-panel-props-shade);
  --ion-color-tint: var(--ion-color-panel-props-tint);
}

.ion-color-panel-rems {
  --ion-color-base: var(--ion-color-panel-rems);
  --ion-color-base-rgb: var(--ion-color-panel-rems-rgb);
  --ion-color-contrast: var(--ion-color-panel-rems-contrast);
  --ion-color-contrast-rgb: var(--ion-color-panel-rems-contrast-rgb);
  --ion-color-shade: var(--ion-color-panel-rems-shade);
  --ion-color-tint: var(--ion-color-panel-rems-tint);
}

.ion-color-panel-lea {
  --ion-color-base: var(--ion-color-panel-lea);
  --ion-color-base-rgb: var(--ion-color-panel-lea-rgb);
  --ion-color-contrast: var(--ion-color-panel-lea-contrast);
  --ion-color-contrast-rgb: var(--ion-color-panel-lea-contrast-rgb);
  --ion-color-shade: var(--ion-color-panel-lea-shade);
  --ion-color-tint: var(--ion-color-panel-lea-tint);
}

.ion-color-panel-dflt {
  --ion-color-base: var(--ion-color-panel-dflt);
  --ion-color-base-rgb: var(--ion-color-panel-dflt-rgb);
  --ion-color-contrast: var(--ion-color-panel-dflt-contrast);
  --ion-color-contrast-rgb: var(--ion-color-panel-dflt-contrast-rgb);
  --ion-color-shade: var(--ion-color-panel-dflt-shade);
  --ion-color-tint: var(--ion-color-panel-dflt-tint);
}